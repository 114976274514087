import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Autocomplete, Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL, THEME_BUTTON, THEME_COLOR } from "../../constants/style";
import VmCalendar from "../../components/calendar";
import { navigate } from "gatsby";
import CloseIcon from '@mui/icons-material/Close';
import { toJS } from "mobx";


const TeacherIndexPage = observer(() => {
  const { rootStore, permissionStore, teacherStore, academicStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showLinkModal, setShowLinkModal] = useState<"" | "One-Many" | "Many-Many">("");
  // Determine is update or create
  const [targetTeacher, setTargetTeacher] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [birthDate, setBirthDate] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [roldId, setRoldId] = useState<string>("0");
  const [page, setPage] = useState<number>(1);
  const [teacherName, setTeacherName] = useState<string>("All");
  const [qualificationId, setQualificationId] = useState<string>("0");
  const [unitId, setUnitId] = useState<string>("0");
  const [unitList, setUnitList] = useState<any>([]);
  const [refreshDisplay, setRefreshDisplay] = useState<any>([])
  const [selectTrainerId, setSelectTrainerId] = useState<number>(0);
  const [selectTrainerList, setSelectTrainerList] = useState<any>([]);
  const encryptedPassword = "ENCRYPTEDPASSWORD";

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0
      || teacherStore.teacherProfileList.data.length > PAGE_SIZE) {
      teacherStore.getTeacherProfileList(PAGE_SIZE, 0);
    }
    teacherStore.getTeacherProfileList(0, 0, true);
    // if (ApiDataResponseListLengthCheck(ApiDataListType.PERMISSION_ROLE) == 0) {
    //   permissionStore.getUserRoleDetailList();
    // }
    if (ApiDataResponseListLengthCheck(ApiDataListType.DOMAIN) == 0
      || academicStore.qualificationList.data.length != ApiDataResponseListLengthCheck(ApiDataListType.DOMAIN)) {
      academicStore.getQualificationList(0, 0);
    }

  }, []);

  const onChangeRole = (event: SelectChangeEvent) => {
    setRoldId(event.target.value as string);
  };

  const onActionTeacher = () => {
    // do check only in update
    if ((!targetTeacher && password !== cPassword) ||
      (targetTeacher && password !== encryptedPassword && cPassword !== password)) {
      rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      return;
    };
    let req: any = {
      // id: 0,
      title,
      firstName,
      lastName,
      middleName,
      birthDate,
      emailAddress,
      username,
      collegeId: GetCollegeId(),
      password: password === encryptedPassword ? oldPassword : password,
      role: roldId,
    }
    if (targetTeacher) {
      req.id = targetTeacher.id;
      teacherStore.updateTeacherProfile(req).then(() => {
        rootStore.notify(t('TEACHER_PROFILE') + t('UPDATED_B'), 'success');
        teacherStore.getTeacherProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
        teacherStore.getTeacherProfileList(0, 0, true);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      teacherStore.updateTeacherProfile(req).then(() => {
        rootStore.notify(t('TEACHER_PROFILE') + t('CREATED_B'), 'success')
        teacherStore.getTeacherProfileList(0, 0, true);
        teacherStore.getTeacherProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('Create failed! This username might already be used, please try again!'), 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = teacherStore.teacherProfileList.data.find(t => t.id == id);
      setTargetTeacher(target);
      setTitle(target.title);
      setFirstName(target.firstName);
      setLastName(target.lastName);
      setMiddleName(target.middleName);
      setBirthDate(target.birthDate.split('T')[0]);
      setEmailAddress(target.emailAddress);
      setUsername(target.username);
      setOldPassword(target.password);
      setPassword(encryptedPassword);
      setRoldId(target.role);
    }
    setShowModal(true);
  }

  const handleShowLinkModal = (id: number) => {
    setShowLinkModal("One-Many");
    teacherStore.getTeacherProfileById(id)
      .then((res) => {
        let target = res;
        const compare = (a, b) => {
          if ((a.code < b.code)) return -1;
          if (a.code > b.code) return 1;
          return 0;
        }
        if (res.unitList) {
          const unitList = res.unitList.sort(compare);
          res.unitList = unitList;
        }
        setUnitList(res.unitList);
        setTargetTeacher(target);
      });


  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setShowLinkModal("");
    setTargetTeacher(null);
    setTitle("");
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setBirthDate("");
    setEmailAddress("");
    setUsername("");
    setCPassword("");
    setOldPassword("");
    setPassword("");
    setRoldId("0");
    setQualificationId("0");
    setUnitId("0");
    setUnitList([]);
    setSelectTrainerId(0);
    setSelectTrainerList([]);
  }

  const handleDeleteModal = (id: number) => {
    let target = teacherStore.teacherProfileList.data.find(t => t.id == id);
    setTargetTeacher(target);
    setShowDeleteModal(true);
  }

  const onChangeTeacher = (event: SelectChangeEvent) => {
    let id = event.target.value;
    setTeacherName(id);
  }

  const onChangeDomainAutoComplete = (code: string) => {
    if (academicStore.qualificationList.data.length > 0) {
      let qual = academicStore.qualificationList.data.find(s => code.split(' ')[0] === s.code);
      if (qual) {
        academicStore.getUnitListByQualificationId(qual.id, 0, 0);
        setQualificationId(qual.id);
      }
    }
  }


  const onChangeUnitAutoComplete = (code: string) => {
    if (academicStore.unitQualificationRelationList.data.length > 0) {
      let unit = academicStore.unitQualificationRelationList.data.find(s => code === s.code);
      if (unit) setUnitId(unit.id);
    }
  }

  const onChangeTrainerList = (name: string) => {
    if (teacherStore.teacherProfileFullList.data.length > 0) {
      let qual = teacherStore.teacherProfileFullList.data.find(s => name.split(' ')[0] === s.firstName && name.split(' ')[1] === s.lastName);
      if (qual) {
        setSelectTrainerId(qual.id)
      }
    }
  }
  return (
    <ContentLayout
      pageName={t('TEACHER')}
      pageHeading={t('TEACHER')}
      breadCrumb={[
        { label: t('TEACHER_PROFILE') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/teacher" }
      ]}
      buttonLabel={t('ADD_A') + t('TEACHER_PROFILE')}
      onClickAction={() => handleShowModal()}
    >
      <Box className="flex justify-end">
      <VmButton
        className="text-white px-4 py-2 mb-4 lg:text-sm 2xl:text-base"
        style={{ height: 'fit-content', backgroundColor: THEME_BUTTON }}
        onClick={() => setShowLinkModal("Many-Many")}
      >
        Link Trainers to Units
      </VmButton>
      </Box>
      <VmTable
        loading={teacherStore.loading && teacherStore.teacherProfileList.data.length == 0}
        page={page}
        paginationCount={teacherStore.teacherProfileList.totalCount == 0 ? 1 : teacherStore.teacherProfileList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          setTeacherName("All");
          teacherStore.getTeacherProfileList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        thead={["ID", t('NAME'), t('USERNAME'), t('BOD'), t('EMAIL'), t('ACTION')]}
        tbody={teacherStore.teacherProfileList.data.length > 0 &&
          teacherStore.teacherProfileList.data.map((teacher, index) => (
            <>
              <Box sx={{ marginY: 1 }} />
              <tr key={`teacher_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{teacher.firstName + " " + teacher.lastName}</td>
                <td className={TD_NORMAL}>{teacher.username}</td>
                <td className={TD_NORMAL}>{teacher.birthDate.split('T')[0]}</td>
                <td className={TD_NORMAL}>{teacher.emailAddress}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => navigate("/teacher/profile",
                      { state: { teacherId: teacher.id } })}
                  >
                    {t('VIEW')}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(teacher.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowLinkModal(teacher.id)}
                  >
                    Link Trainer to Units
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(teacher.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box sx={{ marginY: 1 }} />
            </>
          ))}
      />

      <div className="flex justify-between mt-4">
        <Typography variant="h5" fontWeight={'bold'} marginY={2}
          sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
          {t('VISITS_MONTHLY_OVERVIEW')}
        </Typography>

        <div>
          <FormControl fullWidth>
            <InputLabel>Filter by Responsible Trainer</InputLabel>
            <Select
              label="Filter by Responsible Trainer"
              sx={{ width: 200, background: 'white' }}
              value={teacherName}
              onChange={onChangeTeacher}
            >
              <MenuItem value={"All"}>{t('ALL')}</MenuItem>
              {teacherStore.teacherProfileList.data.map((teacher: any, index: number) => (
                <MenuItem key={`teacher_select_${index}`} value={teacher.firstName + " " + teacher.lastName}>
                  {teacher.firstName + " " + teacher.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="bg-white rounded-lg p-4 mt-4 relative">
        <VmCalendar onFilterByTeacherName={teacherName === "All" ? "" : teacherName} />
      </div>


      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        buttonLoading={teacherStore.loading}
        width={500}
        title={targetTeacher ? t('UPDATE_A') + t('TEACHER_PROFILE') : t('ADD_A') + t('TEACHER_PROFILE')}
        buttonLabel={targetTeacher ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetTeacher ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionTeacher}
        disabledCondition={title === "" || firstName === "" || lastName === "" || username === ""
          || password === "" || (!targetTeacher && cPassword === "") || emailAddress === ""
        }
      >
        <div className="grid grid-cols-2 gap-4">
          <TextField
            value={title}
            label={t('TITLE') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setTitle(value.target.value)}
          />
          <TextField
            value={username}
            label={t('USERNAME') + ' *'}
            variant="outlined"
            disabled={targetTeacher}
            sx={{ width: '100%' }}
            onChange={(value) => setUsername(value.target.value)}
          />
          <TextField
            value={firstName}
            label={t('FIRST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setFirstName(value.target.value)}
          />
          <TextField
            value={lastName}
            label={t('LAST_NAME') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setLastName(value.target.value)}
          />
          <TextField
            value={middleName}
            label={t('MIDDLE_NAME')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setMiddleName(value.target.value)}
          />
          <TextField
            value={birthDate}
            label={t('BOD') + ' *'}
            type="date"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setBirthDate(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={password}
            label={t('PASSWORD') + ' *'}
            type="password"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setPassword(value.target.value)}
          />
          <TextField
            value={cPassword}
            type="password"
            label={t('CONFIRM_PASSWORD') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setCPassword(value.target.value)}
          />
          <TextField
            value={emailAddress}
            label={t('EMAIL') + ' *'}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setEmailAddress(value.target.value)}
          />
          {/* <FormControl fullWidth>
            <InputLabel>{t('USER_ROLE')}</InputLabel>
            {
              permissionStore.roleDetailList.length > 0 &&
              <Select
                label={t('USER_ROLE')}
                sx={{ width: '100%', marginBottom: 2 }}
                value={roldId}
                onChange={onChangeRole}
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {permissionStore.roleDetailList.map((role: any, index: number) => (
                  <MenuItem key={`role_select_${index}`} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            }
          </FormControl> */}
        </div>
      </VmModal>

      <VmModal
        openModal={showLinkModal !== ""}
        onClose={handleCloseModal}
        width={showLinkModal === "Many-Many" ? 1000 : 750}
        title={`Link Trainer${showLinkModal === "Many-Many" ? 's' : ''} to Units: ` + (targetTeacher ? targetTeacher.firstName + " " + targetTeacher.lastName : "")}
        buttonLabel={"Link Now"}
        buttonLabelWithoutConfirm={"Link Confirm"}
        buttonLoading={teacherStore.loading}
        onClickConfirmedButton={() => {
          if (showLinkModal === "One-Many") {
            teacherStore.updateTrainerUnitLink({ trainerId: targetTeacher.id, unitIdList: unitList.map(u => u.id) })
              .then(() => {
                rootStore.notify("Link Success", 'success');
                teacherStore.getTeacherProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
                handleCloseModal();
              })
              .catch(() => rootStore.notify("Link Failed, Please contact network admin!", 'error'));
          } else if (showLinkModal === "Many-Many") {
            if (selectTrainerList.length == 0) rootStore.notify('Please select at least one trainer to link with the units', "warning");
            else {
              console.log({ trainerIds: selectTrainerList.map(s => s.id), unitIdList: unitList.map(u => u.id) });
              teacherStore.updateTrainerUnitsLink({ trainerIds: selectTrainerList.map(s => s.id), unitIds: unitList.map(u => u.id) })
                .then(() => {
                  rootStore.notify("Link Success", 'success');
                  teacherStore.getTeacherProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
                  handleCloseModal();
                })
                .catch(() => rootStore.notify("Link Failed, Please contact network admin!", 'error'));
              }
            }
        }}
      >
        {teacherStore.loading ? <Box textAlign={"center"}><CircularProgress size={30} className="text-theme" /></Box>
          :
          <div className={`grid grid-cols-${showLinkModal == "Many-Many" ? 3 : 2} gap-4`}>
            <div className="">
              <Typography variant="subtitle2">Step 1. Pick qualification to get unit list</Typography>
              <Autocomplete
                // disablePortal
                id="combo-box-demo"
                size="small"
                value={academicStore.qualificationList.data.find(s => s.id == qualificationId) ? academicStore.qualificationList.data.find(s => s.id == qualificationId).code + " " + academicStore.qualificationList.data.find(s => s.id == qualificationId).name : ""}
                options={academicStore.qualificationList.data.map((s) => ({ label: s.code + " " + s.name, id: s.id }))}
                sx={{ width: '100%', marginTop: 1 }}
                onInputChange={(event, newInputValue) => onChangeDomainAutoComplete(newInputValue)}
                renderInput={(params) =>
                  <TextField  {...params} label={t('DOMAIN')} />}
              />
              <Typography variant="subtitle2" marginTop={2}>Step 2. Add all units under the selected qualification</Typography>
              <Button
                variant="outlined"
                className="mr-2 mt-2"
                disabled={+qualificationId == 0}
                onClick={() => {
                  const allCourseList = academicStore.unitQualificationRelationList.data.map((u: any) => u.id);
                  allCourseList.map((id: any) => {
                    if (!unitList.find(u => u.id == id)) {
                      unitList.push(academicStore.unitQualificationRelationList.data.find(u => u.id == id))
                    }
                  })
                  setUnitList(unitList);
                  setRefreshDisplay([]);
                }}>
                Add All Course Units
              </Button>
              <Typography variant="subtitle2" marginTop={1}>or select and add unit individually</Typography>
              {academicStore.loading ? <Box textAlign={"center"}><CircularProgress size={30} className="text-theme" /></Box>
                : <>
                  <Autocomplete
                    // disablePortal
                    size="small"
                    id="combo-box-demo"
                    value={academicStore.unitQualificationRelationList.data.find(s => s.id == unitId) ? academicStore.unitQualificationRelationList.data.find(s => s.id == unitId).code : ""}
                    options={academicStore.unitQualificationRelationList.data.map((s) => ({ label: s.code, id: s.id }))}
                    sx={{ width: '100%', marginTop: 1 }}
                    disabled={+qualificationId == 0 || academicStore.loading}
                    onInputChange={(event, newInputValue) => onChangeUnitAutoComplete(newInputValue)}
                    renderInput={(params) =>
                      <TextField  {...params} label={t('SUBJECT')} />}
                  />
                  <div className="mt-4 flex justify-end">
                    <Button
                      variant="outlined"
                      disabled={+unitId == 0}
                      onClick={() => {
                        if (!unitList.find(u => u.id == unitId)) {
                          unitList.push(academicStore.unitQualificationRelationList.data.find(u => u.id == unitId));
                        }
                        setRefreshDisplay([]);
                      }}>
                      Add Unit
                    </Button>
                  </div>
                </>}
            </div>

            <div>
              <Typography variant="subtitle2">Step 3. Confirm the selected units</Typography>
              <div className="border rounded-md p-2 max-h-96 overflow-auto mt-1">
                {unitList.length == 0 ? <Typography variant="subtitle2" textAlign="center">No Result Found</Typography>
                  : unitList.map((unit: any, index: number) => (
                    <div key={`unit_index_${index}`} className="flex justify-between">
                      <div><Typography>{index + 1}. {unit.code} <span className="text-gray-400 text-sm font-light">({unit.name.substring(0, 20) + "..."})</span></Typography></div>
                      <div className="text-red-500 hover:text-hightlight">
                        <button
                          onClick={() => {
                            unitList.splice(index, 1);
                            setRefreshDisplay([]);
                          }}><CloseIcon /></button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {showLinkModal === "Many-Many" && <div>
              <Typography variant="subtitle2">Step 4. Select Trainers</Typography>
              <Autocomplete
                // disablePortal
                id="combo-box-demo"
                size="small"
                value={teacherStore.teacherProfileFullList.data.find(s => s.id == selectTrainerId) ? teacherStore.teacherProfileFullList.data.find(s => s.id == selectTrainerId).firstName + " " + teacherStore.teacherProfileFullList.data.find(s => s.id == selectTrainerId).lastName : ""}
                options={teacherStore.teacherProfileFullList.data.map((s) => ({ label: s.firstName + " " + s.lastName, id: s.id }))}
                sx={{ width: '100%', marginTop: 1 }}
                onInputChange={(event, newInputValue) => onChangeTrainerList(newInputValue)}
                renderInput={(params) =>
                  <TextField  {...params} label={"Trainer List"} />}
              />
              <Button className="mt-2" variant="outlined" disabled={selectTrainerId == 0} onClick={() => {
                if (!selectTrainerList.includes(teacherStore.teacherProfileFullList.data.find(s => s.id == selectTrainerId))) {
                  selectTrainerList.push(teacherStore.teacherProfileFullList.data.find(s => s.id == selectTrainerId));
                  setSelectTrainerList(selectTrainerList);
                  setRefreshDisplay([]);
                }
              }}>Add Trainer</Button>
              <div className="border rounded-md p-2 max-h-96 overflow-auto mt-1">
                {selectTrainerList.length == 0 ? <Typography variant="subtitle2" textAlign="center">No Result Found</Typography>
                  : selectTrainerList.map((trainer: any, index: number) => (
                    <div key={`trainer_index_${index}`} className="flex justify-between">
                      <div><Typography>{index + 1}. {trainer.firstName + " " + trainer.lastName}</Typography></div>
                      <div className="text-red-500 hover:text-hightlight">
                        <button
                          onClick={() => {
                            selectTrainerList.splice(index, 1);
                            setRefreshDisplay([]);
                          }}><CloseIcon /></button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>}
          </div>}


      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        buttonLoading={teacherStore.loading}
        onClickConfirmedButton={() => teacherStore.deleteTeacherProfile(targetTeacher.id).then(() => {
          rootStore.notify(t('TEACHER_PROFILE') + t('DELETED_B'), 'success')
          teacherStore.getTeacherProfileList(PAGE_SIZE, (page - 1) * PAGE_SIZE);
          handleCloseModal();
        }).catch(() => rootStore.notify('Delete failed, there are other records has linked to this trainer', 'error'))}
      >
        <Typography sx={{ marginBottom: 0 }}>{t('DELETE_CHECK')}: Trainer?</Typography>
        {/* <Typography color="red" variant="caption">
          {t('DELETE_INFO_TEACHER')}
        </Typography> */}
      </VmModal>
    </ContentLayout>
  )
});

export default TeacherIndexPage;
