import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react"
import { Breadcrumbs, CircularProgress, Link, Skeleton, TextField, ThemeProvider, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { THEME_BUTTON, THEME_COLOR, THEME_HIGHLIGHT } from "../constants/style";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import enLocale from '@fullcalendar/core/locales/en-au';
import zhLocale from '@fullcalendar/core/locales/zh-cn';
import twLocale from '@fullcalendar/core/locales/zh-tw';
import { isBrowser, Language, ProdEnvCheck } from "../constants/options";
import { t } from "i18next";
import useStores from "../hooks/use-stores";
import { DateUtils } from "../utilities/date";
import VmModal from "./shared-modal";
import timeGridPlugin from '@fullcalendar/timegrid';

enum ViewMode {
  MONTH = "dayGridMonth",
  WEEK = "timeGridWeek",
}

const VmCalendar = observer(({ onFilterByTeacherName = "" }) => {
  const { groupStore } = useStores();
  const today = new Date();
  const [calendarView, setCalendarView] = useState<string>(ViewMode.MONTH);
  const [calendarEvents, setCalendarEvents] = useState<any>([]);
  const [calendarFilteredEvents, setCalendarFilteredEvents] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<number>(0);
  const [selectedVisit, setSelectedVisit] = useState<any>();
  const [firstDayOfMonth, setFirstDayOfMonth] = useState<string>(
    DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth(), 2)));
  const [lastDayOfMonth, setLastDayOfMonth] = useState<string>(
    DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth() + 1, 1)));
  const [monthDifference, setMonthDifference] = useState<number>(0);
  const calendarRef = useRef();

  useEffect(() => {
    if (calendarEvents.length > 0) onFilterEventsByTeacherName();
    else requestVisitListByMonth("today");
    // @ts-ignore
  }, [onFilterByTeacherName]);

  const requestVisitListByMonth = (action: "today" | "prev" | "next", goToDate?: any) => {
    let tmpMonthDiff: number = monthDifference;
    let firstDay: string = firstDayOfMonth;
    let lastDay: string = lastDayOfMonth;
    switch (action) {
      case "today":
        tmpMonthDiff = 0;
        firstDay = DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth(), 2));
        lastDay = DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth() + 1, 1));
        break;
      case "prev":
        tmpMonthDiff -= 1;
        firstDay = DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth() - tmpMonthDiff, 2));
        lastDay = DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth() + 1 - tmpMonthDiff, 1));
        break;
      case "next":
        tmpMonthDiff += 1;
        firstDay = DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth() - tmpMonthDiff, 2));
        lastDay = DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth() + 1 - tmpMonthDiff, 1));
        break;
    }
    setFirstDayOfMonth(firstDay);
    setLastDayOfMonth(lastDay);
    setMonthDifference(tmpMonthDiff);
    groupStore.getVisitListByDateRange({
      startDate: firstDay, endDate: lastDay.split('T')[0] + " 23:59:00", take: 0, skip: 0
    }).then((res) => {
      if (res.totalCount > 0) {
        let events: any[] = [];
        res.data.map((org: any) => {
          if (org.organisationVisits.length > 0) {
            org.organisationVisits.map((visit: any) => {
              events.push({
                visitId: visit.id,
                orgId: org.id,
                title: visit.name,
                staffName: visit.responsibleTrainerName,
                start: new Date(visit.startTime),
                end: new Date(visit.endTime),
              })
            })
          }
        });
        setCalendarEvents(events);
        setCalendarFilteredEvents(events);
        onFilterEventsByTeacherName(events);
      }
      if (calendarRef.current) {
        // @ts-ignore
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(goToDate ? goToDate : firstDay);
      }
    });
  }

  const onFilterEventsByTeacherName = (eventData?: any) => {
    if (onFilterByTeacherName !== "") {
      setCalendarFilteredEvents(eventData ? eventData.filter((events: any) => events.staffName.includes(onFilterByTeacherName))
        : calendarEvents.filter((events: any) => events.staffName.includes(onFilterByTeacherName)));
    } else {
      setCalendarFilteredEvents(eventData ? eventData : calendarEvents);
    }
  }

  return (
    <>
      {groupStore.loading ? <Skeleton variant="rectangular" width={"100%"} height={300} />
        : <>
        <p className="text-xl">Month</p>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          // @ts-ignore
          ref={calendarRef}
          events={calendarFilteredEvents}
          initialView={calendarView}
          height={600}
          locale={(isBrowser && ProdEnvCheck([localStorage.LANG])) &&
            localStorage.LANG == Language.CHINESE_SIMP ? zhLocale
            : (isBrowser && localStorage.LANG == Language.CHINESE_TRAD) ? twLocale
              : enLocale}
          eventLimit={calendarView == ViewMode.MONTH ? true : false}
          dayMaxEvents={calendarView == ViewMode.MONTH ? 3 : 100}
          eventColor={THEME_BUTTON}
          eventClassNames={"bg-reverseTheme"}
          displayEventEnd={calendarView == ViewMode.WEEK ? true : false}
          nowIndicator
          eventClick={
            function (arg: any) {
              let visitInfo = arg.event._def.extendedProps;
              let target = groupStore.visitInDateRangeList.data.find(org => org.id == visitInfo.orgId)
                .organisationVisits.find(visit => visit.id == visitInfo.visitId);
              setSelectedVisit(target);
              setSelectedOrg(visitInfo.orgId);
              setShowModal(true);
            }
          }
          headerToolbar={{ start: `${calendarView == ViewMode.MONTH && 'prev,next'}`, center: `title`, end: `${calendarView == ViewMode.MONTH ? 'today viewMode' : 'viewMode'}` }}
          customButtons={{
            viewMode: {
              text: t('TOGGLE_MONTH_WEEK_VIEW'),
              click: function () {
                // @ts-ignore
                if (calendarRef.current) {
                  // @ts-ignore
                  const calendarApi = calendarRef.current.getApi();
                  setCalendarView(calendarView == ViewMode.MONTH ? ViewMode.WEEK : ViewMode.MONTH);
                  if (firstDayOfMonth !== DateUtils.convertDateTimeToLocaleString(new Date(today.getFullYear(), today.getMonth(), 2))) {
                    requestVisitListByMonth("today", today);
                  } else {
                    calendarApi.gotoDate(today);
                  }
                  calendarApi.changeView(calendarView == ViewMode.MONTH ? ViewMode.WEEK : ViewMode.MONTH);
                }
              },
            },
            prev: {
              click: function () {
                if (calendarRef.current) {
                  // @ts-ignore
                  const calendarApi = calendarRef.current.getApi();
                  requestVisitListByMonth("next");
                  calendarApi.prev();
                }
              }
            },
            next: {
              click: function () {
                if (calendarRef.current) {
                  // @ts-ignore
                  const calendarApi = calendarRef.current.getApi();
                  requestVisitListByMonth("prev");
                  calendarApi.next();
                }
              }
            },
            today: {
              text: t('TODAY'),
              click: function () {
                if (calendarRef.current) {
                  // @ts-ignore
                  const calendarApi = calendarRef.current.getApi();
                  requestVisitListByMonth("today");
                  calendarApi.today();
                }
              }
            }
          }}
        />
      </>}
      <VmModal
        openModal={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedVisit(null);
          setSelectedOrg(0);
        }}
        // @ts-ignore
        width={500}
        title={showModal && ` ${selectedVisit.name}`}
        showButton={false}
      >
        {selectedVisit && selectedOrg &&
          <table style={{ marginTop: -20, width: '100%' }}>
            <thead><th /><th /></thead>
            <tbody>
              <tr>
                <td className="font-bold">{t('Location')}: </td>
                <td className="text-right">{selectedVisit.finalLocation && selectedVisit.finalLocation.name}</td>
              </tr>
              <tr>
                <td className="font-bold">{t('STUDENT_SINGLE')}: </td>
                <td className="text-right"><Typography>{selectedVisit.studentName}</Typography></td>
              </tr>
              <tr>
                <td className="font-bold">{t('CREATE_TIME')}: </td>
                <td className="text-right">{selectedVisit.createTime.split('T')[0] + " " + selectedVisit.createTime.split('T')[1]}</td>
              </tr>
              <tr>
                <td className="font-bold">{t('UPDATED_TIME')}: </td>
                <td className="text-right">{selectedVisit.updateTime.split('T')[0] + " " + selectedVisit.updateTime.split('T')[1]}</td>
              </tr>
              <tr>
                <td className="font-bold">{t('START_TIME')}: </td>
                <td className="text-right">{selectedVisit.finalStartTime.split('T')[0] + " " + selectedVisit.finalStartTime.split('T')[1]}</td>
              </tr>
              <tr>
                <td className="font-bold">{t('END_TIME')}: </td>
                <td className="text-right">{selectedVisit.finalEndTime.split('T')[0] + " " + selectedVisit.finalEndTime.split('T')[1]}</td>
              </tr>
              <tr>
                <td className="font-bold">Responsible Trainer Name: </td>
                <td className="text-right">{selectedVisit.responsibleTrainerName}</td>
              </tr>
              <tr>
                <td className="font-bold">Conduct Trainer Name: </td>
                <td className="text-right text-highlight">{selectedVisit.conductTrainerName}</td>
              </tr>
              <tr>
                <td className="font-bold">{t('DESCRIPTION')}: </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <TextField
                    value={selectedVisit.description}
                    sx={{ width: '100%' }}
                    multiline
                    rows={3}
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>}
      </VmModal>
    </>
  )
})

export default VmCalendar;
